<template>
    <v-form
        ref="empresaForm"
        v-model="formValid"
        class="form"
    >
        <v-container>
            <div class="header">
                <span
                    id="title"
                    class="text-h4 font-weight-bold"
                >{{ title }}</span>
                <p
                    id="subtitle"
                    class="text-h6 font-weight-light grey--text text--darken-2"
                >
                    {{ subtitle }}
                </p>
            </div>
            <v-row dense>
                <v-col cols="12">
                    <v-text-field
                        v-model="cnpj"
                        v-mask="['##.###.###/####-##']"
                        :error-messages="errors.cnpj"
                        :success-messages="success.cnpj"
                        :readonly="loadingCNPJ"
                        :loading="loadingCNPJ"
                        rounded
                        outlined
                        label="CNPJ"
                        @keyup.prevent="checkCNPJ()"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                    <v-select
                        v-model="regime"
                        :items="regimeTributarios"
                        :error-messages="errors.regime"
                        rounded
                        outlined
                        label="Regime Tributário"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                    <v-select
                        v-model="atividade"
                        :items="atividadesEmpresa"
                        :error-messages="errors.atividade"
                        value=""
                        rounded
                        outlined
                        label="Atividade"
                    />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col
                    cols="6"
                    sm="3"
                    md="4"
                >
                    <v-text-field
                        id="cep"
                        v-model="cep"
                        v-mask="['#####-###']"
                        rounded
                        outlined
                        :error-messages="errors.cep"
                        label="CEP"
                        @input="resetEndereco()"
                        @blur="getCep()"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="8"
                >
                    <v-text-field
                        id="logradouro"
                        v-model="logradouro"
                        rounded
                        readonly
                        filled
                        :title="logradouro"
                        :loading="loadingCep"
                        label="Logradouro"
                    />
                </v-col>
                <v-col
                    cols="4"
                    sm="3"
                    md="4"
                >
                    <v-text-field
                        id="numero"
                        v-model="numero"
                        rounded
                        outlined
                        :error-messages="errors.numero"
                        label="Numero"
                    />
                </v-col>
                <v-col
                    cols="8"
                    sm="7"
                    md="8"
                >
                    <v-text-field
                        id="complemento"
                        v-model="complemento"
                        rounded
                        outlined
                        label="Complemento"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="5"
                    md="5"
                >
                    <v-text-field
                        id="bairro"
                        v-model="bairro"
                        rounded
                        readonly
                        filled
                        :title="bairro"
                        :loading="loadingCep"
                        label="Bairro"
                    />
                </v-col>
                <v-col
                    cols="8"
                    md="5"
                >
                    <v-text-field
                        id="cidade"
                        v-model="cidade"
                        rounded
                        readonly
                        filled
                        :title="cidade"
                        :loading="loadingCep"
                        label="Cidade"
                    />
                </v-col>
                <v-col
                    cols="4"
                    sm="3"
                    md="2"
                >
                    <v-text-field
                        id="uf"
                        v-model="uf"
                        rounded
                        readonly
                        filled
                        :title="uf"
                        :loading="loadingCep"
                        label="UF"
                    />
                </v-col>
            </v-row>
            <v-row
                slot="buttons"
                dense
                justify="space-between"
            >
                <v-col
                    cols="auto"
                    class="mx-auto mx-sm-0"
                >
                    <v-btn
                        rounded
                        filled
                        outlined
                        height="62px"
                        width="162px"
                        class="text-capitalize font-weight-light text-h6"
                        elevation="3"
                        x-large
                        :disabled="loading || loadingCNPJ || loadingCep"
                        @click.prevent="returnStep()"
                    >
                        <v-icon left>
                            mdi-arrow-left
                        </v-icon>
                        Voltar
                    </v-btn>
                </v-col>
                <v-col
                    cols="auto"
                    class="mx-auto mx-sm-0"
                >
                    <v-btn
                        rounded
                        filled
                        color="primary"
                        height="62px"
                        width="162px"
                        class="text-capitalize font-weight-light text-h6"
                        elevation="3"
                        x-large
                        :disabled="$v.$invalid"
                        :loading="loading"
                        @click.prevent="nextStep()"
                    >
                        Continuar
                        <v-icon right>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import { required, minLength } from "vuelidate/lib/validators";
import { cnpj } from "../validators/customValidators";
import { fetchCep, checkAPI } from "@/services/services";

export default {
    name: "Empresa",
    directives: {
        mask,
    },
    props: {
        title: {
            default: "Dados da Empresa",
            type: String,
        },
        subtitle: {
            default: "Preencha o formulário com os dados de sua empresa",
            type: String,
        },
    },
    data() {
        return {
            regimeTributarios: [
                {
                    text: "MEI",
                    value: "mei",
                },
                {
                    text: "Simples Nacional",
                    value: "simples",
                },
            ],
            atividadesEmpresa: [
                {
                    text: "Serviço",
                    value: "servicos",
                    disabled: false
                },
                {
                    text: "Comércio",
                    value: "comercio",
                    disabled: false
                }
            ],
            loadingCep: false,
            loadingCNPJ: false,
            formValid: true,
            success: {
                cnpj: [],
            },
            errors: {
                cep: [],
                nomeFantasia: [],
                cnpj: [],
                cnae: [],
                regime: [],
                atividade: [],
                numero: [],
            },
            loading: false,
        };
    },
    validations: {
        cnpj: { required, minLength: minLength(18), cnpj },
        atividade: { required },
        regime: { required },
        cep: { required, minLength: minLength(9) },
        logradouro: { required },
        numero: { required },
        cidade: { required },
        uf: { required },
    },
    computed: {
        nomeFantasia: {
            get() {
                return this.$store.getters["empresa/nomeFantasia"];
            },
            set(value) {
                this.$store.commit("empresa/nomeFantasia", value);
            },
        },
        cnpj: {
            get() {
                return this.$store.getters["empresa/cnpj"];
            },
            set(value) {
                this.$store.commit("empresa/cnpj", value);
            },
        },
        cnae: {
            get() {
                return this.$store.getters["empresa/cnae"];
            },
            set(value) {
                this.$store.commit("empresa/cnae", value);
            },
        },
        regime: {
            get() {
                return this.$store.getters["empresa/regime"];
            },
            set(value) {
                this.$store.commit("empresa/regime", value);
            },
        },
        atividade: {
            get() {
                return this.$store.getters["empresa/atividade"];
            },
            set(value) {
                this.$store.commit("empresa/atividade", value);
            },
        },
        cep: {
            get() {
                return this.$store.getters["empresa/cep"];
            },
            set(value) {
                this.$store.commit("empresa/cep", value);
            },
        },
        logradouro: {
            get() {
                return this.$store.getters["empresa/logradouro"];
            },
            set(value) {
                this.$store.commit("empresa/logradouro", value);
            },
        },
        numero: {
            get() {
                return this.$store.getters["empresa/numero"];
            },
            set(value) {
                this.$store.commit("empresa/numero", value);
            },
        },
        complemento: {
            get() {
                return this.$store.getters["empresa/complemento"];
            },
            set(value) {
                this.$store.commit("empresa/complemento", value);
            },
        },
        bairro: {
            get() {
                return this.$store.getters["empresa/bairro"];
            },
            set(value) {
                this.$store.commit("empresa/bairro", value);
            },
        },
        cidade: {
            get() {
                return this.$store.getters["empresa/cidade"];
            },
            set(value) {
                this.$store.commit("empresa/cidade", value);
            },
        },
        uf: {
            get() {
                return this.$store.getters["empresa/uf"];
            },
            set(value) {
                this.$store.commit("empresa/uf", value);
            },
        },
    },
    watch: {
        cep() {
            this.errors.cep = [];
            this.$v.cep.required ? null : this.errors.cep.push("Campo inválido");
            this.$v.cep.minLength ? null : this.errors.cep.push("Campo inválido");
        },
        cnpj() {
            this.errors.cnpj = [];
            this.$v.cnpj.required ? null : this.errors.cnpj.push("Campo inválido");
            this.$v.cnpj.minLength ? null : this.errors.cnpj.push("Campo inválido");
            this.$v.cnpj.cnpj ? null : this.errors.cnpj.push("CNPJ Inválido");
        },
        regime() {
            if (this.regime != 'mei'){
                this.atividadesEmpresa[1].disabled = true
            } else {
                this.atividadesEmpresa[1].disabled = false
            }
            this.errors.regime = [];
            this.$v.regime.required
                ? null
                : this.errors.regime.push("Campo inválido");
        },
        atividade() {
            this.errors.atividade = [];
            this.$v.atividade.required
                ? null
                : this.errors.atividade.push("Campo inválido");
        },
        numero() {
            this.errors.numero = [];
            this.$v.numero.required
                ? null
                : this.errors.numero.push("Campo inválido");
        },
    },

    methods: {
        async getCep() {
            if (this.cep.length !== 9) return;
            this.loadingCep = true;
            try {
                const result = await fetchCep(this.cep);
                if (result.erro) this.cep = "";
                this.logradouro = result.logradouro;
                this.complemento = result.complemento;
                this.bairro = result.bairro;
                this.uf = result.uf;
                this.cidade = result.localidade;
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingCep = false;
            }
        },
        returnToStepBasedOnMessage(message) {
            if (
                ["CPF ou Email já cadastrado", "Dados do usuário inválidos"].includes(
                    message
                )
            ) {
                this.$store.commit("setStep", 3);
            }
        },
        async nextStep() {
            if (this.$v.$invalid || !this.formValid) return;
            this.loading = true;
            try {
                const response = await this.$store.dispatch("sendRegistrationForm");
                if (response.status !== 200)
                    throw Error("Houve um erro ao enviar suas informações");
                if (response.data.success !== true) throw Error(response.data.msg);
                this.$store.commit("nextStep");
            } catch (error) {
                this.setSnackbarMessage(error.message);
                this.returnToStepBasedOnMessage(error.message);
            } finally {
                this.loading = false;
            }
        },
        setSnackbarMessage(msg, timeout = 3000) {
            this.$store.commit("updateSnackbarMessage", {
                message: msg,
                timeout,
            });
        },
        returnStep() {
            this.$store.commit("returnStep");
        },
        resetEndereco() {
            this.logradouro = "";
            this.numero = "";
            this.complemento = "";
            this.bairro = "";
            this.cidade = "";
            this.uf = "";
        },
        async checkCNPJ() {
            if (this.$v.cnpj.$invalid || !this.formValid) return;
            this.loadingCNPJ = true;
            try {
                const cnpjRaw = this.cnpj.replace(/\D/g, "");
                const response = await checkAPI.checkCNPJ(cnpjRaw);
                if (response.data.success === false)
                    this.errors.cnpj.push(response.data.msg);
                if (response.data.success === true)
                    this.success.cnpj = "CNPJ disponível";
            } catch (error) {
                if (error.response.status === 429) {
                    this.errors.cnpj.push("Muitas requisições, aguarde um momento");
                    this.success.cnpj = "";
                }
            } finally {
                this.loadingCNPJ = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 40px;
}
</style>
